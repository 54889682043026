import React from "react"
import Consumer from "../layouts/Context"
import 'ssr-intersection-observer'
import { graphql } from "gatsby"
import { InView } from 'react-intersection-observer'

// Components
import SEO from "../components/seo"
import ArticleWipe from "../components/ArticleWipe/ArticleWipe"
import ArticleHeader from "../components/ArticleHeader/ArticleHeader"
import ArticleHeaderBtn from "../components/ArticleHeaderBtn/ArticleHeaderBtn"
import ArticleShare from "../components/ArticleShare/ArticleShare"
import ArticleFooter from "../components/ArticleFooter/ArticleFooter"
import ArticleBio from "../components/ArticleBio/ArticleBio"
import ArticleBottom from "../components/ArticleBottom/ArticleBottom"

class LongForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fixed: true
    }
  }

  bottomInView = (inView, entry) => {
    this.setState({
      fixed: !inView
    })
  }

  render() {
    const post = this.props.data.markdownRemark,
          seoDescription = post.frontmatter.seoDescription,
          seoImage = post.frontmatter.seoImage,
          typeModifier = post.frontmatter.typeModifier,
          title = post.frontmatter.title,
          subtitle = post.frontmatter.subtitle,
          author = post.frontmatter.author,
          authorAvatar = post.frontmatter.authorAvatar,
          authorBio = post.frontmatter.authorBio,
          htmlContent = post.html

    return (
      <>
        <SEO
          title={title}
          description={seoDescription ? seoDescription : post.excerpt}
          image={seoImage}
        />

        <Consumer>
          {context =>
            <ArticleWipe context={context} />
          }
        </Consumer>

        <div className="page-wipe">
          <div className="page-wipe__panel page-wipe__panel--article" ref={pageWipe => (this.pageWipePanel = pageWipe)} />
        </div>

        <div className="article" data-style={typeModifier}>
          <div className="article__inner">
            <div className="container">

              <ArticleHeaderBtn style={typeModifier} pageWipe={this.pageWipePanel} />
              <ArticleHeader modifier="text" title={title} subtitle={subtitle} author={author} />

              <div className="cols">
                <ArticleShare modifier="text" style={typeModifier} col={`m12 t1`} />
                <div className="col m12 t10 d8 dl6">
                  <div className="article__container">
                    <InView
                      onChange={this.contentInView}
                      threshold={0}
                    >
                      {({ inView, ref }) => (
                        <div
                          className="article__content"
                          dangerouslySetInnerHTML={{ __html: htmlContent }}
                          ref={ref}
                          tabIndex="0"
                        />
                      )}
                    </InView>
                  </div>
                </div>
              </div>

              <ArticleFooter fixed={this.state.fixed} />
            </div>
          </div>

          { authorBio && authorAvatar && (
            <ArticleBio author={author} authorAvatar={authorAvatar} authorBio={authorBio} />
          )}

          <InView
            onChange={this.bottomInView}
            threshold={0}
          >
            {({ inView, ref }) => (
              <div className="article__bottom" ref={ref} data-anim={inView}>
                <ArticleBottom pageWipe={this.pageWipePanel} />
              </div>
            )}
          </InView>

        </div>
      </>
    )
  }
}

LongForm.contextType = Consumer

export default LongForm

export const pageQuery = graphql`
  query LongFormBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        seoDescription
        seoImage
        chapter
        typeModifier
        title
        subtitle
        author
        authorAvatar {
          publicURL
        }
        authorBio
      }
    }
  }
`